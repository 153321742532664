<template>
  <div class="provider-detail wrapper page-wrap" v-if="provider">
    <a-spin :spinning="loading">
      <div class="flex provider-left">
        <img :src="provider.provider_logo" />
        <div>
          <div class="provider-name">{{ provider.provider_name }}</div>
          <div class="provider-desc">{{ provider.provider_desc }}</div>
        </div>
      </div>
      <div class="line"></div>
      <a-row type="flex" justify="space-between">
        <a-col>
          <div class="flex flex-between info">
            <div class="info-title">所在位置</div>
            <div class="info-content flex">
              {{ provider.provider_address }}<div @click="goMap(provider)">导航</div>
            </div>
          </div>
        </a-col>
        <a-col>
          <div class="flex flex-between info">
            <div class="info-title">联系电话</div>
            <div class="info-content">{{ provider.provider_phone }}</div>
          </div>
        </a-col>
      </a-row>
      <div
        class="provider-content"
        v-html="provider.provider_introduction"
      ></div>
    </a-spin>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch
} from "@vue/runtime-core";
import { getProviderData } from "@/api/main.js";
import { message } from "ant-design-vue";
import url from "@/utils/url.js";
import { useRoute } from "vue-router";
import { encode, decode } from "js-base64";
export default defineComponent({
  name: "providerDetail",
  components: {},
  setup() {
    const route = useRoute();
    const pageData = reactive({
      provider: null,
      providerId: null,
      loading: true
    });
    const getProviderDataFun = _providerId => {
      getProviderData({ provider_id: _providerId })
        .then(res => {
          if (res.code == 200) {
            pageData.provider = res.data.provider;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
          message.error("网络有误，请稍后再试");
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    const goMap = (_provider) => {
      url.navigateTo("/map", {
        t: encode(`${_provider.provider_location_lng},${_provider.provider_location_lat}`)
      });
    };
    watch(
      () => route.query,
      val => {
        if (route.path == "/provider/detail") {
          let query = decode(val?.t).split(",");
          if (query[0]) {
            pageData.loading = true;
            pageData.providerId = query[0];
            getProviderDataFun(query[0]);
          } else {
            pageData.loading = false;
            pageData.provider = null;
            message.error("暂无信息");
          }
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    return {
      ...toRefs(pageData),
      goMap
    };
  }
});
</script>
<style lang="less" scoped>
.provider-detail {
  margin-top: 40px;
  padding-bottom: 150px;
  .provider-left {
    img {
      width: 80px;
      height: 80px;
      background-color: #ffffff;
      border: solid 2px #f3f5f8;
      border-radius: 80px;
      margin-right: 20px;
    }
    .provider-name {
      color: #212531;
      font-size: 22px;
    }
    .provider-desc {
      color: #212531;
      opacity: 0.6;
      font-size: 18px;
    }
  }
  .line {
    height: 10px;
    background-color: #f3f5f8;
    margin: 40px 0;
  }
  .provider-content {
    color: #212531;
    font-size: 18px;
    line-height: 40px;
    padding: 0 20px;
    margin-top: 50px;
  }
  .info {
    width: 570px;
    border-bottom: 1px solid #dfdfdf;
    height: 60px;
    font-size: 18px;
    .info-title {
      color: #212531;
      opacity: 0.7;
    }
    .info-content {
      color: #212531;
      width: 420px;
      text-align: right;
      >div {
        width: 70px;
        height: 31px;
        line-height: 31px;
        background-color: #1276cb;
        border-radius: 15px;
        color: #ffffff;
        padding: 0 10px;
        margin-left: 10px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
</style>
